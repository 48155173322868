<template>
    <filtre-facturi @schimbaFiltru="filtreaza"></filtre-facturi>
    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="h4">Centralizator restante furnizori</h2>
                <div class="row">
                    <div class="col-lg-4" v-for="tip in ['aviz', 'factura']" :key="tip">
                        <h2 class="h5">{{ tip }}</h2>
                        <table class="table table-sm">
                            <tr v-for="(rest, index) in restDePlata[tip]" :key="index"
                                :style="{ 'background-color': rest.fundal, 'color': rest.text }">
                                <td class="ps-1"><a class="text-decoration-none d-block" :style="{ 'color': rest.text }"
                                        :href="'/furnizor/' + rest.id">
                                        {{ rest.nume }}
                                    </a></td>
                                <td class="text-end pe-1">{{
                                    rest.dePlata.toLocaleString('ro-RO', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })
                                }}
                                </td>
                            </tr>
                            <tr class="bg-danger text-white">
                                <td>Total</td>
                                <td class="text-end">{{
                                    parseFloat(totalDePlata[tip]).toLocaleString('ro-Ro', {
                                        style: 'decimal',
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                    })
                                }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-lg-4">
                        <h2 class="h4">De plata</h2>
                        <table class="table table-sm">
                            <tr v-for="(rest, index) in mainTotal" :key="index"
                                :style="{ 'background-color': rest.fundal, 'color': rest.text }">
                                <td class="ps-1">
                                    <a :href="'/furnizor/' + rest.id" :style="{ 'color': rest.text }"
                                        class="text-decoration-none d-block">{{ rest.nume }}</a>
                                </td>
                                <td class="text-end pe-1">{{
                                    rest.dePlata.toLocaleString('ro-RO', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })
                                }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 bg-danger text-white text-end">
                        {{
                            parseFloat(totalDePlata['gt']).toLocaleString('ro-Ro', {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            })
                        }}
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { API_URL } from "@/config";
    // import AfiseazaFacturaFurnizori from "@/components/de_plata/AfiseazaFacturaFurnizori";
    import router from "@/router";
    import { useAuthStore } from "@/store";
    import FiltreFacturi from "@/components/de_plata/FiltreFacturi";

    export default {
        name: "ListeazaFacturiFurnizoriPlatite",
        components: {
            // AfiseazaFacturaFurnizori,
            FiltreFacturi,
        },
        data() {
            return {
                listaFacturi: [],
                authStore: useAuthStore(),
                destinatie: 'toate',
                // restDePlata: {},
                // totalDePlata: 0,
            }
        },
        computed: {
            listaFacturiFiltrare() {
                if (this.destinatie === 'toate') {
                    return this.listaFacturi;
                } else {
                    Object.filter = (obj, predicate) =>
                        Object.keys(obj)
                            .filter(key => predicate(obj[key]))
                            .reduce((res, key) => (res[key] = obj[key], res), {});

                    return Object.filter(this.listaFacturi, factura => factura.destinatie === this.destinatie)
                }
            },
            totalDePlata() {

                let tp = {
                    'aviz': 0,
                    'factura': 0,
                    'gt': 0,
                },
                    tipuri = [
                        'aviz',
                        'factura'
                    ];
                for (const tip in tipuri) {
                    for (const index in this.restDePlata[tipuri[tip]]) {
                        tp[tipuri[tip]] = parseFloat(tp[tipuri[tip]]) + parseFloat(this.restDePlata[tipuri[tip]][index].dePlata);
                    }
                }
                tp.gt = parseFloat(tp['aviz']) + parseFloat(tp['factura']);

                return tp;
            },
            restDePlata() {
                let ret = {
                    'aviz': {},
                    'factura': {},
                    'total': {}
                };
                for (const key in this.listaFacturiFiltrare) {

                    // if ((this.listaFacturi[key].valoare - plata) > 0) {
                    if (!Object.prototype.hasOwnProperty.call(ret[this.listaFacturiFiltrare[key].tip_document], this.listaFacturiFiltrare[key].id_furnizor)) {
                        ret[this.listaFacturiFiltrare[key].tip_document][this.listaFacturiFiltrare[key].id_furnizor] = {
                            nume: this.listaFacturiFiltrare[key].furnizor,
                            dePlata: this.listaFacturiFiltrare[key].valoare,
                            id: this.listaFacturiFiltrare[key].id_furnizor,
                            fundal: this.listaFacturiFiltrare[key].fundal,
                            text: this.listaFacturiFiltrare[key].text,
                        }
                    } else {
                        ret[this.listaFacturiFiltrare[key].tip_document][this.listaFacturiFiltrare[key].id_furnizor].dePlata = parseFloat(ret[this.listaFacturiFiltrare[key].tip_document][this.listaFacturiFiltrare[key].id_furnizor].dePlata) + parseFloat(this.listaFacturiFiltrare[key].valoare)
                    }


                }
                return ret;
            },
            mainTotal() {
                let ret = {};
                for (const key in this.listaFacturiFiltrare) {

                    // if ((this.listaFacturi[key].valoare - plata) > 0) {
                    if (!Object.prototype.hasOwnProperty.call(ret, this.listaFacturiFiltrare[key].id_furnizor)) {
                        ret[this.listaFacturiFiltrare[key].id_furnizor] = {
                            nume: this.listaFacturiFiltrare[key].furnizor,
                            dePlata: this.listaFacturiFiltrare[key].valoare,
                            id: this.listaFacturiFiltrare[key].id_furnizor,
                            fundal: this.listaFacturiFiltrare[key].fundal,
                            text: this.listaFacturiFiltrare[key].text,
                        }
                    } else {
                        ret[this.listaFacturiFiltrare[key].id_furnizor].dePlata = parseFloat(ret[this.listaFacturiFiltrare[key].id_furnizor].dePlata) + parseFloat(this.listaFacturi[key].valoare)
                    }


                }
                return ret;
            }
        },
        mounted() {
            this.getFacturi()
        },
        methods: {
            filtreaza(destinatie) {
                this.destinatie = destinatie
            },
            getFacturi() {
                let headers = {};
                if (this.authStore.username.token) {
                    headers.Token = this.authStore.username.token;
                    headers.an = localStorage.getItem('anCurent');
                }
                fetch(API_URL + '/lista-facturi-furnizori.php?arata=platite', {
                    headers: headers
                })
                    .then(answer => answer.json())
                    .then(answer => {
                        if (answer.error === false) {
                            this.listaFacturi = answer.msg;
                        } else {
                            if (answer.msg.toLowerCase().includes('token')) {
                                this.authStore.username = null;
                                localStorage.removeItem('username');
                                router.push('/login');
                            }
                        }
                    })
                    .catch(e => console.log(e));
            },
            plataNoua(idFactura) {
                this.$emit('plata-noua', idFactura);
            },
            editeazaFactura(idFactura) {
                this.$emit('editeaza-factura', idFactura);
            }
        }
    }
</script>

<style scoped></style>