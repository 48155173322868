<template>
    <tr :class="{'bg-danger': bgDanger, 'text-white': textWhite, 'bg-success': bgSuccess}"
        :id="'tr_' + factura.id" :data-factura="factura.id">
        <td nowrap>{{ factura.furnizor }}</td>
        <td nowrap>{{ factura.firma }}</td>
        <td nowrap>{{ factura.tip_document }}</td>
        <td nowrap>{{ factura.numar_document }}</td>
        <td nowrap>{{ factura.data_emitere }}</td>
        <td nowrap class="bg-primary">{{
                parseFloat(factura.valoare).toLocaleString('ro-Ro', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }}
        </td>
        <td nowrap>{{ factura.detalii }}</td>
        <td nowrap>
            <div v-if="!this.platit">
                <a :class="{'text-white': textWhite}" href="#"
                   @click.prevent="$parent.plataNoua(factura.id)">plata
                    noua</a> /
                <a href="#" :class="{'text-white': textWhite}"
                   @click.prevent="$parent.editeazaFactura(factura.id)">editeaza</a> /
                <a href="#" :class="{'text-white': textWhite}"
                   @click.prevent="stergeFactura('plati', factura.id)">sterge</a> /
                <a href="#" :class="{'text-white': textWhite}"
                   @click.prevent="$parent.arataProduse(factura.id)">produse</a>
            </div>
            <div v-else>
                <a href="#" :class="{'text-white': textWhite}"
                   @click.prevent="$parent.editeazaFactura(factura.id)">editeaza</a> /
                <a href="#" :class="{'text-white': textWhite}"
                   @click.prevent="stergeFactura('plati', factura.id)">sterge</a> /
                <a href="#" :class="{'text-white': textWhite}"
                   @click.prevent="$parent.arataProduse(factura.id)">produse</a>

            </div>
        </td>
    </tr>
    <tr :id="'restPlata_' + factura.id" :data-factura="factura.id" class="">
        <td colspan="7"></td>
        <td class="text-end text-white">
            <span class="bg-primary p-1">
            Rest plata: {{
                    parseFloat(restPlata).toLocaleString('ro-RO', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })
                }}
            </span>
        </td>
    </tr>

    <tr :id="'plati_' + factura.id" v-if="factura.plati.length > 0" :data-factura="factura.id">
        <td colspan="4">
            <table class="table table-sm table-bordered plati text-success">
                <thead>
                <tr>
                    <th>Data plata</th>
                    <th>Tip document</th>
                    <th>Numar document</th>
                    <th>Valoare platita</th>
                    <th v-if="!this.platit"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="plata in factura.plati" :key="plata.id_plata" :data-plata="plata.id_plata">
                    <td>{{ plata.data_plata }}</td>
                    <td>{{ plata.tip_document }}</td>
                    <td>{{ plata.numar_document }}</td>
                    <td class="text-end">{{
                            parseFloat(plata.valoare_plata).toLocaleString('ro-RO', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })
                        }}
                    </td>
                    <td>
                        <a href="#" @click.prevent="stergeFactura('furnizor-platit', plata.id_plata)">Sterge</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
</template>

<script>
import stergeFactura from "@/minxins/stergeFactura";

export default {
    name: "AfiseazaFacturaFurnizori",
    props: ['factura', 'platit'],
    // emits: ['rest-plata'],
    mixins: [stergeFactura],
    data() {
        return {
            bgDanger: false,
            textWhite: false,
            bgSuccess: false,
        }
    },
    computed: {
        restPlata() {
			console.log(this.factura)

            const totalFactura = parseFloat(this.factura.valoare);
            let platit = 0;
            if (Object.prototype.hasOwnProperty.call(this.factura, 'plati')) {
                if (this.factura.plati.length > 0) {
                    for (let i = 0; i < this.factura.plati.length; i++) {
                        platit += parseFloat(this.factura.plati[i].valoare_plata);
                    }
                }
            } else {
                platit = 0;
            }
            let restPlata = parseFloat(totalFactura - platit).toFixed(2);
            if (parseFloat(restPlata) === 0) {
                this.setSuccessBackground()
                this.setWhiteText()
            } else {
                this.setDangerBackground();
                this.setWhiteText();
            }
            // this.$emit('rest-plata', this.factura.id_furnizor, this.factura.furnizor, restPlata);
            return restPlata;
        }
    },
    methods: {
        setSuccessBackground() {
            this.bgSuccess = true;
        },
        setDangerBackground() {
            this.bgDanger = true;
        },
        setWhiteText() {
            this.textWhite = true;
        }
    }
}
</script>

<style scoped>

</style>